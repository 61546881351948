import React, { useEffect, useState } from "react";
import ProductImg from "./image 3.png";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import "../../assets/styles/customstyle.scss";
import Layout from "../Layout";
import More from "../../assets/icons/keyboard_arrow_bottom.svg";
import Delete from "../../assets/icons/Delete.svg";
import Moins from "../../assets/icons/Moins.svg";
import Plus from "../../assets/icons/Plus.svg";
import Services from "../../services/global.service";
import ConfirmPopup from "./confirmationPopup";
import { tableCellClasses } from "@mui/material/TableCell";
import { Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const IMG_URL = process.env.REACT_APP_FILE_BASE_URL || "";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: theme.palette.action.hover,
  },
}));

const Panier = () => {
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    productId: null,
    addressId: null,
  });
  const qtePacket = 10;
  const prixU = 10;
  const [rows, setRows] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const navigate = useNavigate();
  const handleRowClick = (rowId) => {
    setExpandedRow((prevRow) => (prevRow === rowId ? null : rowId));
  };
  const calculateTotalPrice = () => {
    console.log(rows);
    let p = rows?.reduce(
      (total, row) => total + row?.produit?.prix * row.quantite,
      0
    );
    console.log("heloo", p);
    setTotalPrice(p);
    console.log("10");
  };
  const handleCounter = (productId, addressId, action) => {
    setRows((prevRows) => {
      const updatedRows = prevRows.map((prevRow) => {
        if (prevRow.id === productId) {
          const updatedAddresses = prevRow.addresses.map((address) => {
            if (address.id === addressId) {
              const newQuantity =
                action === "plus"
                  ? address.quantity + parseInt(prevRow.produit.quantiteParCarton)
                  : address.quantity - parseInt(prevRow.produit.quantiteParCarton);
              return { ...address, quantity: newQuantity };
            }
            return address;
          });
  
          const totalQuantity = updatedAddresses.reduce(
            (total, address) => total + address.quantity,
            0
          );
  
          return {
            ...prevRow,
            addresses: updatedAddresses,
            quantite: totalQuantity,
          };
        }
        return prevRow;
      });
      const sortedRows = updatedRows.sort((a, b) => a.code.localeCompare(b.code));
   
      setRows(sortedRows);
  
      // Calculate and update the total price
      calculateTotalPrice();
  
      // const updatedLocalStorage = prevRows.map((row) => ({
      //   product: row.id,
      //   rows: row.addresses,
      // }));
      // localStorage.setItem('panier', JSON.stringify(updatedLocalStorage));
      localStorage.setItem('panier', JSON.stringify(updatedRows));
      return updatedRows;
    });
  };

  const getProduct = async (id) => {
    console.log(id);
    try {
      const response = await Services.getOne(id, "product");
      return response?.data || {};
    } catch (error) {
      console.error("Error fetching product:", error);
      return {};
    }
  };
  const ValidateCommande = () => {
    // Set the 'panier' in local storage
    localStorage.setItem("panier", JSON.stringify(rows));
    navigate("order-summary");
  };
  useEffect(() => {
    const panier = JSON.parse(localStorage.getItem("panier")) || [];
    console.log("panier", panier);
    // Map over the 'panier' array
    const mappedPanierPromise = panier?.map(async (item) => {
      // Access the product details asynchronously

      const product = await getProduct(item.id);

      // Access the array of addresses ('rows') for the current product
      const addresses = item?.addresses;

      // Return the updated item
      return {
        id: item?.id, // Assuming 'id' is the unique identifier for products
        produit: product,
        quantite: addresses?.reduce(
          (total, address) => total + address?.quantity,
          0
        ),
        addresses: addresses,
      };
    });

    // Use Promise.all to wait for all promises to resolve
    Promise.all(mappedPanierPromise).then((mappedPanier) => {
      // Log the mapped 'panier' array
      console.log(mappedPanier);
      setRows(mappedPanier);
      calculateTotalPrice();
    });
    
  }, [totalPrice]);



  const handleDeleteProduct = (productId) => {
    // Définir la confirmation de suppression
    setDeleteConfirmation({
      open: true,
      productId: productId,
      addressId: null,
    });
  };

  const handleDeleteAddress = (productId, addressId) => {
    // Déclencher la fenêtre contextuelle de confirmation pour la suppression du produit et de l'adresse
    setDeleteConfirmation({
      open: true,
      productId: productId,
      addressId: addressId,
    });
  };
  const handleDeleteProductNew = (productId) => {
    // Retrieve 'panier' from local storage
    const panier = JSON.parse(localStorage.getItem("panier")) || [];

    // Find the index of the product in the 'panier' array
    const productIndex = panier.findIndex((item) => item?.id == productId);

    if (productIndex !== -1) {
      // Remove the product from the 'panier' array
      panier.splice(productIndex, 1);

      // Update local storage with the modified 'panier' array
      localStorage.setItem("panier", JSON.stringify(panier));

      // Update state to trigger a re-render
      setRows([...panier]);

      // Optionally, you can also recalculate the total price here if needed
      // window.location.reload();
    }
  };
  const handleDeleteAddressNew = (productId, addressId) => {
    // Retrieve 'panier' from local storage
    const panier = JSON.parse(localStorage.getItem("panier")) || [];
    console.log("panier", panier);
    // Find the index of the product in the 'panier' array
    const productIndex = panier.findIndex((item) => item?.id == productId);
    
    console.log('0000', panier[productIndex]);

    if (productIndex !== -1) {


      // Find the index of the address in the 'rows' array
      const addressIndex = panier[productIndex].addresses.findIndex(
        (address) => address.id === addressId
      );

      if (addressIndex !== -1) {
        // Remove the address from the 'rows' array

        panier[productIndex].addresses.splice(addressIndex, 1);

        // Update local storage with the modified 'panier' array
        localStorage.setItem("panier", JSON.stringify(panier));

        // Update state to trigger a re-render
        setRows(JSON.parse(localStorage.getItem("panier")));
        // window.location.reload();
        calculateTotalPrice();
      }
      console.log('length', panier[productIndex]?.addresses?.length)
      if (panier[productIndex]?.addresses?.length < 1) {
        handleDeleteProduct(productId);
      }
    }
  };

  const handleConfirmDelete = () => {
    const { productId, addressId } = deleteConfirmation;
  
    // Effectuer la suppression en fonction de la confirmation
    if (addressId) {
      // Gérer la suppression de l'adresse
      // Retrieve 'panier' from local storage
      const panier = JSON.parse(localStorage.getItem("panier")) || [];
       // Find the index of the product in the 'panier' array
      const productIndex = panier.findIndex((item) => item?.id == productId);
  
      if (productIndex !== -1) {
         // Find the index of the product in the 'panier' array
        const addressIndex = panier[productIndex].addresses.findIndex(
          (address) => address.id === addressId
        );
  
        if (addressIndex !== -1) {
           // Remove the address from the 'rows' array
          panier[productIndex].addresses.splice(addressIndex, 1);
           // Update local storage with the modified 'panier' array
          localStorage.setItem("panier", JSON.stringify(panier));
           // Update state to trigger a re-render
          setRows(JSON.parse(localStorage.getItem("panier")));
          calculateTotalPrice();
        }
  
        if (panier[productIndex].addresses.length < 1) {
             // Si toutes les adresses du produit sont supprimées, supprimez le produit du panier
        panier.splice(productIndex, 1);
        localStorage.setItem("panier", JSON.stringify(panier));
        setRows(JSON.parse(localStorage.getItem("panier")));
        calculateTotalPrice();
        }
      }
    } else {
      // Gérer la suppression du produit
      const panier = JSON.parse(localStorage.getItem("panier")) || [];
      const productIndex = panier.findIndex((item) => item?.id == productId);
  
      if (productIndex !== -1) {
        const rowsLength = panier[productIndex].addresses.length;
        panier.splice(productIndex, 1);
        localStorage.setItem("panier", JSON.stringify(panier));
        setRows(JSON.parse(localStorage.getItem("panier")));
        calculateTotalPrice();
       // lorsque la dernière adresse d'un produit est supprimée, le produit est supprimé du panier. 
        if (rowsLength <= 1) {
          handleDeleteProduct(productId);
        }
      }
    }

    // Fermer la fenêtre contextuelle de confirmation de suppression
    setDeleteConfirmation({ open: false, productId: null, addressId: null });
  };
  const handleCancelDelete = () => {
    setDeleteConfirmation({ open: false, productId: null, addressId: null });
  };
  const handleReturn = () => {
    window.history.back();
  };
  useEffect(() => {
    calculateTotalPrice();
  }, [rows]);
  return (
    <Layout>
      <div className="container min-vh-100 mt-32">
        <div className="commande mt-24">
          <h1>Mon Panier</h1>
          {/* Product information at the top */}
          <div className="container mt-24">{/* ... product info ... */}</div>
          {/* Table for displaying products and addresses */}
          {rows.length > 0 ? (
            <TableContainer component={Paper} className="aug-table">
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ width: "30%" }}>
                      Produit
                    </StyledTableCell>
                    <StyledTableCell style={{ width: "20%" }}>
                      Quantité
                    </StyledTableCell>
                    <StyledTableCell style={{ width: "10%" }}>
                      Prix unitaire HT
                    </StyledTableCell>
                    <StyledTableCell style={{ width: "10%" }}>
                      Prix Total HT
                    </StyledTableCell>
                    <StyledTableCell style={{ width: "10%" }}></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((row, index) => (
                    <React.Fragment key={index}>
                      {/* Product row */}
                      <StyledTableRow
                        className={expandedRow === index ? "expanded" : ""}
                        hover
                        onClick={() => handleRowClick(index)}
                      >
                        <StyledTableCell className="width-img">
                          <p className="d-flex align-items-center m-0">
                            {" "}
                            <img
                              src={
                                IMG_URL + row?.produit?.products_photos[0]?.url
                              }
                              alt="product img"
                              className="img-product  w-54"
                            />
                            <div className="ml-16 col">
                              {" "}
                              <h5>{row?.produit?.nom}</h5>
                              <p className="text-ref">
                                <span className="mr-8">
                                  {" "}
                                  Réf: {row?.produit?.reference}{" "}
                                </span>
                                |
                                <span className="ml-8">
                                  {" "}
                                  {row?.produit?.fournisseur}
                                </span>
                              </p>
                            </div>
                          </p>
                        </StyledTableCell>
                        <StyledTableCell className="width-quantity">
                          {row?.quantite}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.produit?.prix} €
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.produit?.prix * row?.quantite} €
                        </StyledTableCell>

                        <StyledTableCell
                          style={{ width: "10%" }}
                          align="right"
                          id="margin_button"
                        >
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRowClick(index);
                            }}
                          >
                            <img
                              src={More}
                              alt="more icon"
                              className={expandedRow === row.id ? "open" : ""}
                            />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteProduct(row.produit.id);
                            }}
                          >
                            <img src={Delete} alt="delete icon" />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                      {/* Expanded row with addresses */}
                      {expandedRow === index && (
                        <TableRow className="p-0 m-0">
                          <StyledTableCell colSpan={5} className="p-0 m-0">
                            <Accordion expanded={true} elevation={0}>
                              <AccordionDetails>
                                <Table>
                                  <TableBody>
                                    {row?.addresses?.map((liv) => (
                                      <TableRow key={liv.adresse}>
                                        <StyledTableCell
                                          style={{ width: "25%" }}
                                        >
                                          {liv.code} {liv.entite}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          style={{ width: "30%" }}
                                        >
                                          <div className="d-flex p-1">
                                            <button
                                              className="cta-qte p-2"
                                              onClick={() =>
                                                handleCounter(
                                                  row.id,
                                                  liv.id,
                                                  "minus"
                                                )
                                              }
                                              disabled={liv?.quantity === 0}
                                            >
                                              <img
                                                src={Moins}
                                                alt="minus icon"
                                              />
                                            </button>
                                            <button className="cta-qte p-2">
                                              {liv?.quantity}
                                            </button>
                                            {/* <TextField id="filled-basic "  variant="filled" value={row.quantite} /> */}
                                            <button
                                              className="cta-qte p-2"
                                              onClick={() =>
                                                handleCounter(
                                                  row.id,
                                                  liv.id,
                                                  "plus"
                                                )
                                              }
                                            >
                                              <img src={Plus} alt="plus icon" />
                                            </button>
                                          </div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                          style={{ width: "10%" }}
                                        >
                                          {row?.produit?.prix} €
                                        </StyledTableCell>
                                        <StyledTableCell
                                          style={{ width: "10%" }}
                                        >
                                          {liv.quantity * row?.produit?.prix} €
                                        </StyledTableCell>
                                        <StyledTableCell
                                          style={{ width: "10%" }}
                                          align="right"
                                        >
                                          <IconButton
                                            size="small"
                                            onClick={() =>
                                              handleDeleteAddress(
                                                row.produit.id,
                                                liv.id
                                              )
                                            }
                                          >
                                            <img
                                              src={Delete}
                                              alt="delete icon"
                                            />
                                          </IconButton>
                                        </StyledTableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </AccordionDetails>
                            </Accordion>
                          </StyledTableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <>
              {" "}
              <h5>Panier vide...</h5>
              <a href="/catalogue" className="cta mt-24">
                Voir catalogue
              </a>
            </>
          )}
        </div>
      </div>
      {rows.length > 0 ? (
        <div className="fixedBanner row">
          <div className="col-md-6">
            <button className="btn-secondary" onClick={handleReturn}>
              Annuler
            </button>
          </div>
          <div className="col-md-6 text-end">
            <h5>
              Total: {totalPrice} € HT
              <button
                className="ml-10 btn-primary"
                onClick={() => {
                  ValidateCommande();
                }}
                disabled={totalPrice <= 0}
              >
                Valider au panier
              </button>
            </h5>
          </div>
        </div>
      ) : (
        ""
      )}
     <ConfirmPopup
        isOpen={deleteConfirmation.open}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        message="Confirmez-vous la suppression ?"
      />
    </Layout>
  );
};

export default Panier;
