import ProductForm from "../../../components/admin/formAddProduct";
import LayoutAdmin from "../../LayoutAdmin";
import BreadcrumbPage from "../../../components/breadcrump";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Link } from "react-router-dom";
import UserForm from "./../../../components/admin/formAddUser";

const AddUsers = () => {
  return (
    <LayoutAdmin>
      <div className="container">
        <BreadcrumbPage
          parent="Utilisateurs"
          page="Liste des utilisateurs"
          sub={"Ajouter utilisateur"}
          parentLink='/admin/users'
        />
        <Link to={`/admin/users`} className="cta-retour">
          <KeyboardArrowLeftIcon /> Retour
        </Link>
        <h1 className="mt-20">Ajouter utilisateur</h1>
        <UserForm />
      </div>
    </LayoutAdmin>
  );
};
export default AddUsers;

