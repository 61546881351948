import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import "../../components/modals/modal.scss";

const ConfirmPopup = ({ isOpen, onCancel, onConfirm, message }) => {
  return (
    <div>
      <Modal isOpen={isOpen} toggle={onCancel} className="modal-augusta">
        <ModalBody className="custom-modal-body">
          <h3 className="text-center mt-16">{message}</h3>
        </ModalBody>
        <ModalFooter className="row mt-32">
          <div className="col text-end">
            <Button className="primarycustom" onClick={onCancel}>
              Annuler
            </Button>
          </div>
          <div className="col">
            <Button className="secondarycustom" onClick={onConfirm}>
              Confirmer
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ConfirmPopup;
