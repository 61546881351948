import React, { useState } from 'react';
import { TextField, Button, IconButton, InputAdornment } from '@mui/material';
import Eye from '../assets/icons/Mdp.svg';
import Return from '../assets/icons/keyboard_arrow_left.svg';
import { useParams,useNavigate  } from 'react-router-dom';
import AuthService from '../services/auth.service';

const FormChange = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const params= useParams();
  const token=params.token
  const handleToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setError('Les mots de passe ne correspondent pas.');
      return;
    }
    const data={
      token:token,
      newPassword:password
    }
    console.log('data change password' ,data)
    AuthService.changePassword(token,password)
    .then((res)=>{console.log(res);
    // Redirect to the login page after successful password reset
      navigate('/login');})
    .catch((err)=>{console.log(err)})
   // event.preventDefault();
  // Add your password reset logic here
  };

  return (
    <div className="login">
      <div className="form-login ">
        <div className="pt-10 ">
          <a href="/" className="cta mt-24 ">
            <img src={Return} alt="return icon" />
            Retour
          </a>
        </div>
        <h1 className="title-login mt-24">Réinitialiser le mot de passe</h1>
        <form onSubmit={handleSubmit}>
          <div className="password-input-container mt-24">
            <TextField
              type={showPassword ? 'text' : 'password'}
              id="password"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
                setError('');
              }}
              placeholder="Nouveau mot de passe"
              fullWidth
              variant="filled"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleToggle} edge="end">
                      {showPassword ? (
                        <img src={Eye} className="password-icon" alt="password show" />
                      ) : (
                        <img src={Eye} className="password-icon" alt="password-hide" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  color: "rgba(18, 16, 16, 1)", // Change the default label color
                  "&.MuiInputLabel-shrink": {
                    color: "rgba(251, 38, 59, 1)", // Change the floating label color
                  },
                },
              }}
              sx={{
                "& .MuiInput-root": {
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottom: "none", // Remove the bottom border on hover
                  },
                },
              }}
              
              className="input-aug"
            />
          </div>

          <div className="password-input-container mt-16">
            <TextField
              type={showPassword ? 'text' : 'password'}
              id="confirmPassword"
              value={confirmPassword}
              onChange={(event) => {
                setConfirmPassword(event.target.value);
                setError('');
              }}
             
              placeholder="Confirmation du nouveau mot de passe"
              fullWidth
              variant="filled"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleToggle} edge="end">
                      {showPassword ? (
                        <img src={Eye} className="password-icon" alt="password show" />
                      ) : (
                        <img src={Eye} className="password-icon" alt="password-hide" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  color: "rgba(18, 16, 16, 1)", // Change the default label color
                  "&.MuiInputLabel-shrink": {
                    color: "rgba(251, 38, 59, 1)", // Change the floating label color
                  },
                },
              }}
              sx={{
                "& .MuiInput-root": {
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottom: "none", // Remove the bottom border on hover
                  },
                },
              }}
              className="input-aug"
            />
          </div>

          <div>
            <button type="submit" className="btn-primary mt-24">
              Réinitialiser
            </button>
          </div>
          <span className="text-danger pt-5">{error}</span>
        </form>
      </div>
    </div>
  );
};

export default FormChange;
