import React, { useState } from "react";
import { TextField, Button, IconButton, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";

const FormLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    AuthService.login(email, password).then(
      (response) => {
        console.log("res", response);
        if (response?.user?.role &&  response?.user?.role === "3") {
          navigate("/admin/products");
        } else if(response?.user?.role === "1") {
          navigate("/catalogue");
        }
      },
      (error) => {
        if (error.response && error.response.status === 400) {
          console.log("Le mot de passe saisi est invalide, veuillez réessayer", error.response.data.message);
          setError(error.response.data.message);
          return;
        }
        if (error.response && error.response.status === 404) {
          console.log("Email saisi est invalide, veuillez réessayer");
          setError("User not found");
          return;
        }
        console.log("err", error);

        if (error.response && error.response.status === 401) {
          setError("Le mot de passe saisi est invalide, veuillez réessayer");
        }
      }
    );
  };

  return (
    <div className="login">
      <div className="form-login">
        <h1 className="title-login">Connexion</h1>
        <form onSubmit={handleSubmit}>
          <div className="mt-24">
            <TextField
              type="email"
              id="email"
              label="Adresse e-mail"
              variant="filled"
              fullWidth
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
                setError("");
              }}
              // InputLabelProps={{
              //   style: {
              //     color: "rgba(18, 16, 16, 1)", // Change the default label color
              //     "&.MuiInputLabel-shrink": {
              //       color: "rgba(251, 38, 59, 1)", // Change the floating label color
              //     },
              //   },
              // }}
              InputLabelProps={{
                sx: { color: "rgba(18, 16, 16, 1)", "&.Mui-focused": { color: "rgba(251, 38, 59, 1)" } },
              }}
              sx={{
                "& .MuiInput-root": {
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottom: "none", // Remove the bottom border on hover
                  },
                },
              }}
              className="input-aug"
            />
          </div>

          <div className="password-input-container mt-16">
            <TextField
              type={showPassword ? "text" : "password"}
              id="password"
              label="Mot de passe"
              variant="filled"
              fullWidth
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
                setError("");
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleToggle} edge="end">
                      {showPassword ? (
                        <Visibility style={{ color: "#FB263B" }} />
                      ) : (
                        <VisibilityOff style={{ color: "#FB263B" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              // InputLabelProps={{
              //   style: {
              //     color: "rgba(18, 16, 16, 1)", // Change the default label color
              //     "&.MuiInputLabel-shrink": {
              //       color: "rgba(251, 38, 59, 1)", // Change the floating label color
              //     },
              //   },
              // }}
              InputLabelProps={{
                sx: { color: "rgba(18, 16, 16, 1)", "&.Mui-focused": { color: "rgba(251, 38, 59, 1)" } },
              }}
              sx={{
                "& .MuiInput-root": {
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottom: "none", // Remove the bottom border on hover
                  },
                },
              }}
              className="input-aug"
            />
          </div>

          <div className="pt-10 text-end">
            <a href="/resetPassword" className="cta mt-24 text-end">
              Mot de passe oublié
            </a>
          </div>

          <div>
            <button type="submit" className=" btn-primary mt-24">
              Se connecter
            </button>
          </div>

          <span className="text-danger pt-5">{error}</span>
        </form>
      </div>
    </div>
  );
};

export default FormLogin;
