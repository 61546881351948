import * as React from "react";
import Typography from "@mui/material/Typography";
import DataTable from "react-data-table-component";
import Paper from "@mui/material/Paper";
import SortIcon from "@mui/icons-material/ArrowDownward";

export default function Datatables({columns, data}) {

  return (
    <div>
      <Paper>
        <DataTable
          title=""
          columns={columns}
          data={data}
          sortIcon={<SortIcon />}
          pagination={true}
        />
      </Paper>
      <Typography variant="subtitle1" component="div">
        Total : {data.length}
      </Typography>
      <br />
    </div>
  );
}
