import React, { useState } from 'react'
import Return from '../assets/icons/keyboard_arrow_left.svg'
import { TextField } from '@mui/material';
import AuthService from '../services/auth.service';
const FormReset=()=>{
    const [email, setEmail] = useState('');

  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  
  const handleSubmit = (event) => {
    event.preventDefault();
    AuthService.resetPassword(email).then(
      (Response) => {
        console.log("res", Response);
        setMessage(Response)
        //navigate("/alertes");
        //localStorage.getItem('role') === "ROLE_ADMIN" ? navigate('/admin/animateurs') : navigate('/dashboard/events');
      },
      (error) => {
       setError(error)
        console.log("err", error);
        // Réactiver le bouton de connexion si l'erreur est due à un mot de passe incorrect
       
      }
    );
  };
return(
    <div className="login">
        <div className="form-login ">
        <div className=" pt-10 ">
                <a
                href="/"
                  className="cta mt-24 "
                >
                    <img src={Return} alt="return icon" />
                  Retour
                </a>
              </div>
            <h1 className="title-login mt-24">Réinitialiser le mot de passe</h1>
            <form onSubmit={handleSubmit}>
             
              <div className='mt-24'>
              <TextField
              type="email"
              id="email"
              label="Adresse e-mail"
              variant="filled"
              fullWidth
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
                setError("");
              }}
              InputLabelProps={{
                style: {
                  color: "rgba(18, 16, 16, 1)", // Change the default label color
                  "&.MuiInputLabel-shrink": {
                    color: "rgba(251, 38, 59, 1)", // Change the floating label color
                  },
                },
              }}
              sx={{
                "& .MuiInput-root": {
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottom: "none", // Remove the bottom border on hover
                  },
                },
              }}
              className="input-aug"
            />
              </div>
             
              

            
              <div>
              <button type="submit" className="btn-primary  mt-24">
                Rénitialiser
              </button>
              </div>
              <span className="text-danger pt-5">{error}</span>
              <span className="text-success pt-5">{message}</span>
            </form>
          </div>
    </div>
   
)
}
export default FormReset