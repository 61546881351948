import logo from "./logo.svg";
import { Route, Routes, BrowserRouter, redirect } from "react-router-dom";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Import at the top of your file
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import "./assets/styles/ui.scss";
import "./assets/styles/breadcrumb.scss";
import "./assets/styles/datagrid.scss";
import Login from "./views/login/login";
import Reset from "./views/login/Reset";
import ChangePassword from "./views/login/changePassword";
import Catalogue from "./views/catalogue/catalogue";
import ListProducts from "./views/admin/products/list";
import AddProducts from "./views/admin/products/add";
import EditProducts from "./views/admin/products/edit";
import DetailsProduct from "./views/catalogue/detailsProdcut";
import Contact from "./views/footer/contact";
import Commande from "./views/commandes/commande";
import ListAdresses from "./views/admin/adresses/list";
import AddAdresses from "./views/admin/adresses/add";
import EditAdresses from "./views/admin/adresses/edit";
import ListUsers from "./views/admin/users/list";
import AddUsers from "./views/admin/users/add";
import EditUsers from "./views/admin/users/edit";
import Profil from "./views/profil/profil";
import DetailsCommande from "./views/profil/detailsCommande";
import Adresses from "./views/profil/addresses";
import Panier from "./views/panier/panier";
import OrderSummary from "./views/panier/orderSummary";
import ViewProducts from './views/admin/products/view';
import Logout from './views/login/logout';
import UpdateInformation from "./views/profil/informationsUpdate";
import ListCommandes from './views/admin/commandes/list';
import ViewCommande from './views/admin/commandes/view';
import EditProfilAdresses from "./views/profil/editAdresse";
import Mentions from "./views/footer/mentionslegales";
import Cgu from "./views/footer/cgu";
import Pc from "./views/footer/pc";
import './i18n/index';
function App() {
  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
          
          <Route path="/resetPassword" element={<Reset />} />
          <Route path="/ChangePassword/:token" element={<ChangePassword />} />
          <Route element={<ProtectedRoutes role="1" />}> 
            <Route path="/catalogue" element={<Catalogue />} />
            <Route path="/profil/:id" element={<Profil />} />
            <Route path="/profil/adresses" element={<Adresses />} />
            <Route path="/profil/adresses/:id" element={<EditProfilAdresses />} />
            <Route
              path="/profil/detailCommande/:id"
              element={<DetailsCommande />}
            />
            <Route path="/contact" element={<Contact />} />
            <Route path="/mentions" element={<Mentions />} />
            <Route path="/cgu" element={<Cgu />} />
            <Route path="/pc" element={<Pc />} />
            <Route path="/catalogue/:id" element={<DetailsProduct />} />
            <Route path="/catalogue/commandeMulti/:id" element={<Commande />} />
            <Route path="/panier" element={<Panier />} />
            <Route path="/panier/order-summary" element={<OrderSummary />} />
            <Route path="/profil/update" element={<UpdateInformation />} />
          </Route>
          <Route element={<ProtectedRoutes role="3" />}>
            <Route path="/admin/products" element={<ListProducts />} />
            <Route path="/admin/products/add" element={<AddProducts />} />
            <Route path="/admin/products/edit/:id" element={<EditProducts />} />
            <Route path="/admin/products/view/:id" element={<ViewProducts />} />

            <Route path="/admin/adresses" element={<ListAdresses />} />
            <Route path="/admin/adresses/add" element={<AddAdresses />} />
            <Route path="/admin/adresses/edit/:id" element={<EditAdresses />} />

            <Route path="/admin/users" element={<ListUsers />} />
            <Route path="/admin/users/add" element={<AddUsers />} />
            <Route path="/admin/users/edit/:id" element={<EditUsers />} />

            <Route path="/admin/commandes" element={<ListCommandes />} />
            <Route path="/admin/commandes/:id" element={<ViewCommande />} />

          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
