import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AuthService from '../../services/auth.service';

import { toast } from 'react-toastify';
const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Ancien mot de passe est requis'),
  newPassword: Yup.string()
    .required('Nouveau mot de passe est requis')
    .min(6, 'Le mot de passe doit avoir au moins 6 caractères'),
  confirmPassword: Yup.string()
    .required('Confirmation du nouveau mot de passe est requise')
    .oneOf([Yup.ref('newPassword'), null], 'Les mots de passe ne correspondent pas'),
});

const ChangePasswordConnected = () => {
  const initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleTogglePasswordVisibility = (field) => {
    switch (field) {
      case 'oldPassword':
        setShowOldPassword((prevShow) => !prevShow);
        break;
      case 'newPassword':
        setShowNewPassword((prevShow) => !prevShow);
        break;
      case 'confirmPassword':
        setShowConfirmPassword((prevShow) => !prevShow);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (values, { resetForm }) => {
    // Handle form submission logic here
    console.log(values);
   // resetForm();
    const token=localStorage.getItem('token')
    const data={
      old_password:values.oldPassword,
      new_password: values?.newPassword,
      confirm_password:values?.confirmPassword
    }
    AuthService.PasswordChange(data)
    .then((res)=>{
      toast.success('Password changed successfully!', {
        position: 'top-right',
        autoClose: 3000, // Close the toast after 3000 milliseconds (3 seconds)
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    })
    .catch((err)=>{
      console.error('Error changing password:', err);
      toast.error('Failed to change password. Please try again.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    })
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ChangePasswordSchema}
      onSubmit={handleSubmit}
    >
      
      {({ values, handleChange }) => (
        <Form className="profil min-vh-80">
          <h2>Mot de passe</h2>
          <div className="col-5">
            <Field name="oldPassword">
              {({ field }) => (
                <TextField
                  {...field}
                  id="oldPassword"
                  type={showOldPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handleTogglePasswordVisibility('oldPassword')}
                          edge="end"
                        >
                          {showOldPassword ? <Visibility style={{ color: '#FB263B' }} /> : <VisibilityOff style={{ color: '#FB263B' }} />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: {
                      color: "rgba(18, 16, 16, 1)", // Change the default label color
                      "&.MuiInputLabel-shrink": {
                        color: "rgba(251, 38, 59, 1)", // Change the floating label color
                      },
                    },
                  }}
                  sx={{
                    "& .MuiInput-root": {
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottom: "none", // Remove the bottom border on hover
                      },
                    },
                  }}
                  className="input-aug w-100"
                  label="Ancien mot de passe"
                  variant="filled"
                />
              )}
            </Field>
            <ErrorMessage name="oldPassword" component="div" className="error" />
          </div>
          <div className="col-5 mt-16">
            <Field name="newPassword">
              {({ field }) => (
                <TextField
                  {...field}
                  id="newPassword"
                  type={showNewPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handleTogglePasswordVisibility('newPassword')}
                          edge="end"
                        >
                          {showNewPassword ? <Visibility style={{ color: '#FB263B' }} /> : <VisibilityOff style={{ color: '#FB263B' }} />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: {
                      color: "rgba(18, 16, 16, 1)", // Change the default label color
                      "&.MuiInputLabel-shrink": {
                        color: "rgba(251, 38, 59, 1)", // Change the floating label color
                      },
                    },
                  }}
                  sx={{
                    "& .MuiInput-root": {
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottom: "none", // Remove the bottom border on hover
                      },
                    },
                  }}
                  className=" input-aug w-100"
                  label="Nouveau mot de passe"
                  variant="filled"
                />
              )}
            </Field>
            <ErrorMessage name="newPassword" component="div" className="error" />
          </div>
          <div className="col-5 mt-16">
            <Field name="confirmPassword">
              {({ field }) => (
                <TextField
                  {...field}
                  id="confirmPassword"
                  type={showConfirmPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handleTogglePasswordVisibility('confirmPassword')}
                          edge="end"
                        >
                          {showConfirmPassword ? <Visibility style={{ color: '#FB263B' }} /> : <VisibilityOff style={{ color: '#FB263B' }} />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  label="Confirmation du nouveau mot de passe"
                  variant="filled"
                  InputLabelProps={{
                    style: {
                      color: "rgba(18, 16, 16, 1)", // Change the default label color
                      "&.MuiInputLabel-shrink": {
                        color: "rgba(251, 38, 59, 1)", // Change the floating label color
                      },
                    },
                  }}
                  sx={{
                    "& .MuiInput-root": {
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottom: "none", // Remove the bottom border on hover
                      },
                    },
                  }}
                  className="input-aug w-100"
                />
              )}
            </Field>
            <ErrorMessage name="confirmPassword" component="div" className="error" />
          </div>
          <button type="submit" className='btn-primary mt-32'>
            Enregistrer les modifications
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePasswordConnected;
