import ProductForm from "../../../components/admin/formAddProduct";
import LayoutAdmin from "../../LayoutAdmin";
import BreadcrumbPage from "../../../components/breadcrump";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Link } from "react-router-dom";

const AddProducts = () => {
  return (
    <LayoutAdmin>
      <div className="container">
        <BreadcrumbPage
          parent="Produits"
          page="Liste des produits"
          sub={"Ajouter produit"}
          parentLink='/admin/products'
        />
        <Link to={`/admin/products`} className="cta-retour">
          <KeyboardArrowLeftIcon /> Retour
        </Link>

        <h1 className="mt-20">Ajouter produit</h1>
        <ProductForm />
      </div>
    </LayoutAdmin>
  );
};
export default AddProducts;
