import LayoutAdmin from "../../LayoutAdmin";
import ProductFormEdit from "./../../../components/admin/formEditProduct";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Services from "../../../services/global.service";
import BreadcrumbPage from "../../../components/breadcrump";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const EditProducts = () => {
  const params = useParams();
  const [data, setdata] = useState();

  useEffect(() => {
    Services.getAll("product/" + params.id).then(
      (response) => {
        console.log(response);
        if (response.data) {
          setdata(response.data);
        }
        // navigate("/catalogue");
      },
      (error) => {}
    );
  }, []);

  return (
    <LayoutAdmin>
      <div className="container">
        <BreadcrumbPage
          parent="Produits"
          page="Liste des produits"
          sub={"Modifier produit"}
          parentLink='/admin/products'
        />

        <Link to={`/admin/products`} className="cta-retour">
          <KeyboardArrowLeftIcon /> Retour
        </Link>
        <h1 className="mt-20">Modifier produit1111</h1>
        {data ? <ProductFormEdit product={data} /> : "Aucun produit"}
        
      </div>
    </LayoutAdmin>
  );
};
export default EditProducts;
