import LayoutAdmin from "../../LayoutAdmin";
import ProductFormEdit from "../../../components/admin/formEditProduct";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Services from "../../../services/global.service";
import BreadcrumbPage from "../../../components/breadcrump";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ImageSlider from "../../../components/imageSlider";
import DetailsP from "../../../components/details";
import UploadFiles from "../../../components/upload-file";
import DataTable from "../../../components/datatable";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
const ViewCommande = () => {
  const params = useParams();
  const [data, setdata] = useState();
  const [details, setDetails] = useState();

  const getDetails = () => {
    Services.getOne(params.id, "commandes").then((res) => {
      console.log("res details commande", res?.data);
      setdata(res?.data);
    });
  };
  const initialValues = {
    statut: data?.statut,
    note: data?.note,
  };

  const onSubmit = (values) => {
    // Handle form submission, e.g., update the command with the new values
    console.log("values update commande", values);
    Services.update(values, params.id, "commandes")
      .then((res) => {
        console.log("res update commande", res);
        toast.success("Commande modifiée!", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3000 milliseconds (3 seconds)
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .ctach((err) => {
        console.log("err update commande", err);
        toast.error("Erreur lors de modification !!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  };
  const csvData = [
    { label: "Reference", key: "reference" },
    { label: "Statut", key: "statut" },
    { label: "Date commande", key: "date" },
    { label: "Total commande", key: "total" },
    { label: "Note", key: "note" },
    { label: "Article", key: "article" },
    { label: "PrixArticle", key: "articlePrice" },
    { label: "Email Client", key: "client" },
    { label: "Client tel", key: "clientTel" },
    { label: "Client", key: "clientName" },
    { label: "adresse", key: "adresse" },
    { label: "qte_adresse", key: "qte" },
    { label: "responsable", key: "responsable" },
    { label: "phone", key: "phone" },
    { label: "complement", key: "complement" },
    { label: "entite", key: "entite" },
    { label: "pays", key: "pays" },
    { label: "type", key: "type" },
    { label: "ville", key: "ville" },
  ];
  useEffect(() => {
    console.log("initialValues:", initialValues);
    getDetails();
  }, []);
  const getCSVData = (data) => {
    // Check if data and addresses are available
    if (!data) {
      return [];
    }
    console.log("csv", data);
    // Map through addresses and format data for CSV export
    // Convert the array to a comma-separated string
    //   const formattedData = data?.commandes.flatMap((command) =>
    //   command?.addresses?.map((address) => ({
    //     reference: command.reference,
    //     statut: command.statut,
    //     note: command.note,
    //     client: command.client,
    //     article: command.article,
    //     subTotal: address.subTotal,
    //     addressId: address.addressId,
    //     addressQuantity: address.addressQuantity,
    //     // Add more fields as needed
    //   }))
    // );
    const formattedData = data?.commandes?.flatMap((row) => {
      const addresses = row?.commandes_articles_adresses?.map((adr) => ({
        adresse: adr?.commandes_adresse?.adresse,
        qte: adr?.qte,
        responsable: adr?.commandes_adresse?.responsable,
        phone: adr?.commandes_adresse?.phone,
        complement: adr?.commandes_adresse?.complement,
        entite: adr?.commandes_adresse?.entite,
        pays: adr?.commandes_adresse?.pays,
        type: adr?.commandes_adresse?.type,
        ville: adr?.commandes_adresse?.ville,
      }));
    
      return addresses.map((address) => ({
        reference: data?.reference,
        date: data?.created_at,
        total: data?.total,
        statut: data?.statut,
        note: data?.note,
        client: data?.commandes_client?.email,
        clientTel: data?.commandes_client?.phone,
        clientName: `${data?.commandes_client?.nom} ${data?.commandes_client?.prenom}`,
        article: row?.article?.nom,
        articlePrice: row?.article?.prix,
        ...address,
      }));
    });
    
    console.log("formattedData:", formattedData);
    return formattedData;
  };
  return (
    <LayoutAdmin>
      <div className="container min-vh-80">
        <BreadcrumbPage
          parent="Commandes"
          page="Liste des commandes"
          sub={"Détail Commande"}
          parentLink='/admin/commandes'
        />
        <Link to={`/admin/commandes`} className="cta-retour">
          <KeyboardArrowLeftIcon /> Retour
        </Link>
        {/* <h1 className="mt-20">Détail de la Commande</h1> */}
        <div className="commande mt-24">
          <h1>Commande {data?.reference}</h1>

          <div className="container mt-24">
            {/* CSV Export Button */}
            <div className="text-end">
              {data && (
                <CSVLink
                  data={getCSVData(data)} // Pass the formatted data directly
                  headers={csvData}
                  filename={"commande_data.csv"}
                  className="btn-primary ms-2"
                  separator=","
                >
                  Export to CSV
                </CSVLink>
              )}
            </div>
            <div className="update">
              {data && (
                <Formik initialValues={initialValues} onSubmit={onSubmit}>
                  {({ values, handleChange }) => (
                    <Form>
                      {/* Status Field */}
                      <div>
                        <FormControl
                          variant="filled"
                          sx={{ m: 1, minWidth: 120 }}
                        >
                          {/* <InputLabel id="demo-simple-select-filled-label">
                          Statut
                        </InputLabel> */}
                          <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            name="statut" // Make sure to set the name to match the Formik field name
                            value={
                              values.statut
                                ? values.statut
                                : data?.statut
                                ? data?.statut
                                : ""
                            }
                            defaultChecked={data?.statut}
                            defaultValue={data?.statut}
                            onChange={handleChange}
                            onBlur={handleChange}
                            className="input-aug"
                          >
                            <MenuItem value="En attente">En attente</MenuItem>
                            <MenuItem value="Annulée">Annulée</MenuItem>
                            <MenuItem value="Validée">Validée</MenuItem>
                            <MenuItem value="Livrée">Livrée</MenuItem>
                          </Select>
                        </FormControl>
                      </div>

                      {/* Comments Field */}
                      <div>
                        <TextField
                          id="note"
                          name="note"
                          variant="filled"
                          aria-label="note"
                          multiline
                          rows={4}
                          placeholder="Commentaires..."
                          style={{ width: "100%", marginTop: "16px" }}
                          value={values.note}
                          defaultValue={data?.note}
                          onChange={handleChange}
                          InputLabelProps={{
                            style: {
                              color: "rgba(18, 16, 16, 1)",
                              "&.MuiInputLabel-shrink": {
                                color: "rgba(251, 38, 59, 1)",
                              },
                            },
                          }}
                          inputProps={{
                            style: {
                              "&.MuiInput-root": {
                                "&:hover:not(.Mui-disabled):before": {
                                  borderBottom: "none",
                                },
                              },
                            },
                          }}
                          className="input-aug"
                        />

                        <ErrorMessage name="note" component="div" />
                      </div>

                      {/* Submit Button */}
                      <div className="text-end mt-24">
                        <button type="submit" className="btn-primary">
                          Sauvegarder
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
            <div className="mt-24">
              {data && <DataTable data={data?.commandes} />}
            </div>
          </div>
        </div>
        {/* {data ? <ProductFormEdit product={data} /> : "Aucun produit"} */}
        {/* 
        <div className="container min-vh-100 mt-32">
          <div className="row">
            <div className="col-md-6">
              <UploadFiles id={details?.id} photos={details?.products_photos}/>
            </div>
            <div className="col-md-6">
              <DetailsP data={details} />
            </div>
          </div>
        </div> */}
      </div>
    </LayoutAdmin>
  );
};
export default ViewCommande;
