
import "../assets/styles/card.scss";
import React, { useState, useRef } from "react";
import Card from "react-bootstrap/Card";
import Shopping from "../assets/icons/ShoppingRed.svg";
import ShoppingDisabled from "../assets/icons/Shoppingdisabled.svg";
import { useNavigate } from "react-router-dom";
import ModalAugusta from "./modals/modal";
const IMG_URL = process.env.REACT_APP_FILE_BASE_URL || "";

const CardComponent = ({ data }) => {
  const buttonRef = useRef(null);
  const navigate = useNavigate();
  const handleCardClick = () => {
    // Navigate to the product page
    navigate(`/catalogue/${data.id}`);
  };
  const handleAddToCartClick = () => {
    console.log("here");
    navigate(`/catalogue/commandeMulti/${data?.id}`);
    // Open the modal
    console.log(data?.id, " added to cart");
  };

  const handleClick = (e) => {
    // Check if the click originated from the button
    if (buttonRef.current && buttonRef.current.contains(e.target)) {
      handleAddToCartClick();
    } else {
      handleCardClick();
    }
  };

  return (
    <div className="col-md-3 mb-4">
      <Card
        style={{ width: "100%" }}
        className="card-aug"
        onClick={handleClick}
      >
      <div className="h-300"><Card.Img variant="top" src={IMG_URL + data?.products_photos[0]?.url} /></div>  
       
        <Card.Body>
          <Card.Title>
            <h4>{data.nom}</h4>
          </Card.Title>
          <Card.Text className="ref-txt">
           <span className="mr-8"> Réf: {data.reference}</span> | <span className="ml-8">{data.fournisseur}</span>
          </Card.Text>
          <button className="cta px-0" ref={buttonRef} disabled={data?.archive}>
            <img src={data?.archive ? ShoppingDisabled : Shopping} alt="shop icon" className="mr-8" />
            Ajouter au panier
          </button>
        </Card.Body>
      </Card>
    </div>
  );
};
export default CardComponent;
