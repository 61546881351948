import { useEffect, useState } from "react";
import CardCommande from "../../components/cardCommande";
import Services from "../../services/global.service";

const Historique=()=>{
  const [historique, setHistorique] =useState([])
  const getCommandes=()=>{
    Services.getAll('commandes/owner')
    .then((res)=>{
      console.log('historique',res)
      setHistorique(res?.data)
    })
    .catch((err)=>{
      console.log(err)
    })
  }
    const data={}
    useEffect(()=>{
      getCommandes()
    },[])
return(
    <div className="profil">
      <h2>Historique des commandes</h2>  
      <div className="cards mt-24 row">
        {historique.map((row)=>{
          return(
            <CardCommande data={row} />
          )
        })}
        
       
      </div>
    </div>
    
)
}
export default Historique;