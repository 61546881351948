
import Footer from "../components/footer";
import SideBar from "../components/sidebar";
function LayoutAdmin(props){
return(
    <div className="">
   
    <SideBar />
       <div className="col-12">
        {props.children}
        </div> 
        <Footer admin={true}/>
    </div>

)
}
export default LayoutAdmin;