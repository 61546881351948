import { useEffect, useState } from 'react';
import './profil.scss'
import AuthService from '../../services/auth.service';
const Information=()=>{
    const [info, setInfo]=useState()
    const getMyProfil=()=>{
        AuthService.profil().then((res)=>{
            console.log(res);
            setInfo(res)
        }).catch((err)=>{console.log(err)})
    }
    useEffect(()=>{
        getMyProfil()
    },[])
return(
    <div className="profil min-vh-80">
       <h2>Informations personnelles</h2> 
        <div className="row mt-24">
            <div className="col"><h5>Nom</h5><p>{info?.nom}</p></div>
            <div className="col"><h5>Prénom</h5><p>{info?.prenom}</p></div>
            <div className="col"><h5>Poste</h5><p>{info?.poste}</p></div>
            <div className="col"><h5>Business unit</h5><p>{info?.bunit}</p> </div>
        </div>
        <div className="row mt-16">
            <div className="col"><h5>Adresse email</h5><p>{info?.email}</p></div>
            <div className="col"><h5>Téléphone</h5><p>{info?.phone}</p></div>
            <div className="col"></div>
            <div className="col"> </div>
        </div>
        <div className='mt-24'>
            <a className='btn-primary mt-32' href='/profil/update'>Modifier</a>
        </div>
          
    </div>
   
)
}
export default Information;