import { Link } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";

const BreadcrumbPage = ({ parent, page, sub,parentLink }) => {
  console.log('ppp',parentLink)
  return (
    <div className="row">
      <div className="col-md-12">
        <Breadcrumbs aria-label="breadcrumb" className="page-breadcrumb">
          <label>{parent}</label>
          <Link underline="hover" color="inherit" to={parentLink} className="elem">
            {page}
          </Link>

          {sub ? (
            <Link underline="hover" color="inherit" href="#" className="elem">
              {sub}
            </Link>
          ) : (
            ""
          )}
        </Breadcrumbs>
      </div>
    </div>
  );
};
export default BreadcrumbPage;
