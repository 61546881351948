// ProductForm.js
import React, { useState, useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "react-quill/dist/quill.snow.css";
import Services from "./../../services/global.service";
import Alerts from "./Alerte";
import { TextField } from "@mui/material";

const validationSchema = Yup.object().shape({
  // Define validation rules for each field
  nom: Yup.string().required("Nom est obligatoire"),
  prenom: Yup.string().required("prénom est obligatoire"),
  phone: Yup.string().matches(/^\+[0-9]{11}$/, 'Le numéro de téléphone doit commencer par +xx et contenir 9 chiffres')
  ,
  poste: Yup.string().required("poste est obligatoire"),
  // bunit: Yup.string().required("Photo est obligatoire"),
  email: Yup.string().required("email est obligatoire"),
  // password: Yup.string().required("Fiche technique est obligatoire"),
  role: Yup.number().required("le rôle est obligatoire"),
});

const UserFormEdit = ({usr}) => {
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState();
  const [desc, setDesc] = useState();
  const [fiche, setFiche] = useState();
  const [visible, setVisible] = useState(false);
  const [color, setColor] = useState();
  const [urole, setUrole] = useState();
  const roles = [
    {
      value: "3",
      label: "Administrateur",
    },
    {
      value: "1",
      label: "Utilisateur",
    },
  ];

  const fileRef = useRef();

  return (
    <>
      <Alerts
        color={color}
        msg={message}
        visible={visible}
        setVisible={setVisible}
      />
      <Formik
        initialValues={{
          nom: usr.nom,
          prenom: usr.prenom,
          phone: usr.phone,
          poste: usr.poste,
          bunit: usr.bunit,
          email: usr.email,
          role: usr.role,
          ids: usr.id
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          // same shape as initial values
          console.log(values);
          setStatus("loading");
          setMessage("");

          Services.create(values, "users/update").then(
            (response) => {
              console.log("res", response.data.status);
              if (response.data.status && response.data.status === 1) {
                setMessage("Utilisateur modifié avec success");
                setVisible(true);
                setColor("success");
              } else {
                setMessage(response.data.message);
                setVisible(true);
                setColor("danger");
              }
              // navigate("/catalogue");
            },
            (error) => {
              console.log("inputs not valide", error.response.data.message);
              setMessage("Erreur de modification");
              setVisible(true);
              setColor("danger");
              return;
            }
          );
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
          touched,
          values,
        }) => (
          <Form className="form-container">
            <div className="row mt-12">
              <div className="col">
                {/* form-floating */}
                <TextField
                  type="text"
                  id="nom"
                  name="nom"
                  label="nom"
                  variant="standard"
                  error={!!touched.nom && !!errors.nom}
                  helperText={touched.nom && errors.nom}
                  defaultValue={values.nom}
                  onChange={handleChange}
                />
              </div>
              <div className="col">
                <TextField
                  type="text"
                  id="prenom"
                  name="prenom"
                  label="Prénom"
                  variant="standard"
                  error={!!touched.prenom && !!errors.prenom}
                  helperText={touched.prenom && errors.prenom}
                  defaultValue={values.prenom}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row mt-12">
              <div className="col">
                <TextField
                  type="text"
                  id="phone"
                  name="phone"
                  label="Téléphone"
                  variant="standard"
                  error={!!touched.phone && !!errors.phone}
                  helperText={touched.phone && errors.phone}
                  defaultValue={values.phone}
                  onChange={handleChange}
                />
              </div>
              <div className="col">
                <TextField
                  type="text"
                  id="poste"
                  name="poste"
                  label="Poste"
                  variant="standard"
                  error={!!touched.poste && !!errors.poste}
                  helperText={touched.poste && errors.poste}
                  defaultValue={values.poste}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row mt-12">
              <div className="col">
                <TextField
                  type="text"
                  id="bunit"
                  name="bunit"
                  label="Business unit"
                  variant="standard"
                  error={!!touched.bunit && !!errors.bunit}
                  helperText={touched.bunit && errors.bunit}
                  defaultValue={values.bunit}
                  onChange={handleChange}
                />
              </div>
              <div className="col">
                <TextField
                  type="text"
                  id="email"
                  name="email"
                  label="Adresse Email"
                  variant="standard"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  defaultValue={values.email}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row mt-12">
              <div className="col-md-6">
                <TextField
                  id="role"
                  name="role"
                  select
                  label="role"
                  defaultValue={values.role}
                  SelectProps={{
                    native: true,
                  }}
                  variant="standard"
                  error={!!touched.role && !!errors.role}
                  onChange={handleChange}
                  helperText={touched.role && errors.role}
                >
                  {roles.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </div>
            </div>
            <div className="text-end">
              <button type="submit" className="btn-primary mt-20 ">
                Sauvegarder
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UserFormEdit;
