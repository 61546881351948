// i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your translations
import translationsEN from '../locales/en/en.json';
import translationsFR from '../locales/fr/fr.json';

const resources = {
  en: {
    translation: translationsEN,
  },
  fr: {
    translation: translationsFR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en', // Set default language
  fallbackLng: 'en', // Fallback language
  interpolation: {
    escapeValue: false, // React already escapes values
  },
});

export default i18n;
