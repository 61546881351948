import React, { useState, useEffect } from "react";

// React Dropzone
import { useDropzone } from "react-dropzone";
import Services from "../services/global.service";
import Alerts from "./admin/Alerte";
import DeleteIcon from "@mui/icons-material/Delete";

const IMG_URL = process.env.REACT_APP_FILE_BASE_URL || "";

// Based on the default React Dropzone image thumbnail example
// The `thumbButton` style positions the edit button in the bottom right corner of the thumbnail
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
  padding: 20,
};

const thumb = {
  position: "relative",
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const thumbButton = {
  position: "absolute",
  right: 10,
  bottom: 10,
};

function UploadFiles({ id, photos }) {
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState("");
  const [visible, setVisible] = useState(false);
  const [color, setColor] = useState();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      const formData = new FormData();

      formData.append("id", id);
      acceptedFiles.map((file) => formData.append("images[]", file));

      Services.createMultipart(formData, "product/upload").then(
        (response) => {
          console.log("res", response.data);
          window.location.reload(false);
        },
        (error) => {
          console.log("inputs not valide", error.response.data.message);
          setMessage(error.response.data.message);
          return;
        }
      );
    },
  });

  const deleteline = (idfile) => {
    Services.deleteByid(idfile, 'photos/delete').then(
      (response) => {
        console.log("res", response.data);
        if (response.data.status === 1) {
          window.location.reload(false);
        } else {
        }
        // navigate("/catalogue");
      },
      (error) => {
        console.log("inputs not valide", error.response.data);
        return;
      }
    );
  };

  const thumbs = files.map((file, index) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt="" />
      </div>
    </div>
  ));
  let thumbs_db = "";
  if (photos) {
    thumbs_db = photos.map((file, index) => (
      <div style={thumb} key={file.id}>
        <div style={thumbInner} className="cadre">
          <DeleteIcon onClick={() => deleteline(file.id)} />
          <img src={IMG_URL + file.url} style={img} alt="" />
        </div>
      </div>
    ));
  }

  useEffect(
    () => () => {
      // Make sure to revoke the Object URL to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <section className="container">
      <Alerts
        color={color}
        msg={message}
        visible={visible}
        setVisible={setVisible}
      />
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <p>Drag & Drop vos images içi</p>
      </div>
      {/* <aside style={thumbsContainer}>{thumbs}</aside> */}
      <aside style={thumbsContainer}>{thumbs_db}</aside>
    </section>
  );
}

export default UploadFiles;
