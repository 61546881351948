import LayoutAdmin from "../../LayoutAdmin";
import ProductFormEdit from "./../../../components/admin/formEditProduct";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Services from "../../../services/global.service";
import BreadcrumbPage from "../../../components/breadcrump";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ImageSlider from "./../../../components/imageSlider";
import DetailsP from './../../../components/details';
import UploadFiles from './../../../components/upload-file';

const ViewProducts = () => {
  const params = useParams();
  const [data, setdata] = useState();
  const [details, setDetails] = useState();

  const getDetails = () => {
    Services.getOne(params.id, "product").then((res) => {
      console.log("res details product", res?.data);
      setDetails(res?.data);
    });
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <LayoutAdmin>
      <div className="container">
        <BreadcrumbPage
          parent="Produits"
          page="Liste des produits"
          sub={"Modifier produit"}
          parentLink='/admin/products'
        />
        <Link to={`/admin/products`} className="cta-retour">
          <KeyboardArrowLeftIcon /> Retour
        </Link>
        <h1 className="mt-20">Modifier produit</h1>
        {/* {data ? <ProductFormEdit product={data} /> : "Aucun produit"} */}

        <div className="container min-vh-100 mt-32">
          <div className="row">
            <div className="col-md-6">
              <UploadFiles id={details?.id} photos={details?.products_photos}/>
            </div>
            <div className="col-md-6">
              <DetailsP data={details} />
            </div>
          </div>
        </div>
      </div>
    </LayoutAdmin>
  );
};
export default ViewProducts;
