import Layout from "../Layout";
import  { tableCellClasses } from "@mui/material/TableCell";

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Typography,
  } from '@mui/material';
  import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from "@mui/material/styles";
import Retour from "../../assets/icons/keyboard_arrow_left.svg";
import React, { useEffect, useState } from "react";
import DataTable from "../../components/datatable";
import { useParams } from "react-router-dom/dist";
import Services from "../../services/global.service";
const DetailsCommande=()=>{
    const [expandedRow, setExpandedRow] = useState(null);
    const [data, setData] = useState([]);

    const params=useParams();
    const getDetailsCommande=()=>{
      Services.getOne(params.id,'commandes')
      .then(
        (res)=>{
          console.log('get commande',res)
          setData(res?.data)
        })
        .catch((err)=>{
          console.log(err)
        })
    }
    const handleRowClick = (rowId) => {
      setExpandedRow(expandedRow === rowId ? null : rowId);
    };

    const rows=  [
        {
          id: 1,
          produit: {nom:"Code + Entité de livraison",prix:20},
          qteTotal:50,
          prixTotal:800,
          livraison: [{adresse:'x1',facturation:'f1',qte:5,prixTotalHt:500},{adresse:'x1',facturation:'f1',qte:5,prixTotalHt:500}],
    
        },
        {
          id: 2,
          produit: {nom:"Code + Entité de livraison",prix:20},
          qteTotal:50,
          prixTotal:800,
          livraison: [{adresse:'x1',facturation:'f1',qte:5,prixTotalHt:500},{adresse:'x1',facturation:'f1',qte:5,prixTotalHt:500}],
    
        },
        {
          id: 3,
          produit: {nom:"Code + Entité de livraison",prix:20},
          qteTotal:50,
          prixTotal:800,
          livraison: [{adresse:'x1',facturation:'f1',qte:5,prixTotalHt:500},{adresse:'x1',facturation:'f1',qte:5,prixTotalHt:500}],
    
        },
        {
          id: 4,
          produit: {nom:"Code + Entité de livraison",prix:20},
          qteTotal:50,
          prixTotal:800,
          livraison: [{adresse:'x1',facturation:'f1',qte:5,prixTotalHt:500},{adresse:'x1',facturation:'f1',qte:5,prixTotalHt:500}],
    
        },
        {
          id: 5,
          produit: {nom:"Code + Entité de livraison",prix:20},
          qteTotal:50,
          prixTotal:800,
          livraison: [{adresse:'x1',facturation:'f1',qte:5,prixTotalHt:500},{adresse:'x1',facturation:'f1',qte:5,prixTotalHt:500}],
    
        },
        {
          id: 6,
          produit: {nom:"Code + Entité de livraison",prix:20},
          qteTotal:50,
          prixTotal:800,
          livraison: [{adresse:'x1',facturation:'f1',qte:5,prixTotalHt:500},{adresse:'x1',facturation:'f1',qte:5,prixTotalHt:500}],
    
        },
        {
          id: 7,
          produit: {nom:"Code + Entité de livraison",prix:20},
          qteTotal:50,
          prixTotal:800,
          livraison: [{adresse:'x1',facturation:'f1',qte:5,prixTotalHt:500},{adresse:'x1',facturation:'f1',qte:5,prixTotalHt:500}],
    
        },
        {
          id: 8,
          produit: {nom:"Code + Entité de livraison",prix:20},
          qteTotal:50,
          prixTotal:800,
          livraison: [{adresse:'x1',facturation:'f1',qte:5,prixTotalHt:500},{adresse:'x1',facturation:'f1',qte:5,prixTotalHt:500}],
        },
      ];
      const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
    
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }));
      useEffect(()=>{
        getDetailsCommande();
      },[])
return(
    <Layout>
        <div className="container min-vh-100 mt-32">
      
      <div className="bread-crumb">
        <span className="bread-item">
          <a className="cta" href='/profil/1'>Profil</a> /
          <a className="cta" href='/profil/3'> Historique des commandes</a> / {data?.reference}
        </span>
      </div>
      <div className="mt-24">
        <a className="cta-retour" href="/profil/3">
          <img src={Retour} alt="retour icon" className="mr-8" /> Retour
        </a>
      </div>

      <div className="commande mt-24">
        <h1>Commande {data?.reference}</h1>
   
        <p className="text-ref">{new Date(data?.created_at).toLocaleDateString('fr-FR')}</p>
        <h4 >{data?.total} € HT</h4>
        <p className="">{data?.note}</p>
        
        <div className=" mt-24">
        <DataTable rows={rows} data={data.commandes}/>
        </div>
      </div>
    </div>
    </Layout>
)
} 
export default DetailsCommande;