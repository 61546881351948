import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import ModalAugusta from '../../components/modals/modal';
import ModalDisconnect from '../../components/modals/modalDisconnect';
const Sidebar=({page,setPage})=>{
    const navigate=useNavigate()
    const drawerWidth = 240;
    const [open, setOpen] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    
    const handlePageChange = (newPage) => {
        setPage(newPage);
      };
      const handleDisconnect = () => {
        // Handle the logic for disconnecting
        // For example, you can clear user authentication, redirect, etc.
        localStorage.setItem('token','')
        navigate('/')
        console.log('Disconnected');
      };

    const handleDrawer = () => {
      setOpen(!open);
    };
    const hideModal = () => {
      setOpenModal(!openModal);
    };
return(
  
    <Drawer
        className="sidebar-profil"
        variant="persistent"
        anchor="left"
        open={open} 
        >
           {openModal ? (
          <ModalDisconnect
            isOpen={openModal}
            setIsOpen={setOpenModal}
            hideModal={hideModal}
            btnPrimary="Se déconnecter"
            function1={handleDisconnect}
            function2={hideModal}
            btnSecondary="Annuler"
            title="Êtes-vous sûr(e) de vouloir vous déconnecter ?"
            text=""
          />
        ) : (
          ""
        )}
      <List className="sub-sidebar">
        <ListItem 
        onClick={()=>{setPage(1)}}
        className={clsx({ 'active-link': page === 1 })}
        
        >
          <ListItemText primary="Informations personnelles" />
        </ListItem>
        <ListItem 
        onClick={()=>{setPage(2)}}
        className={clsx({ 'active-link': page === 2 })}
        
        >
          <ListItemText primary="Modification de mot de passe" />
        </ListItem>
        <ListItem onClick={()=>{setPage(3)}}
        className={clsx({ 'active-link': page === 3 })}
        >
          <ListItemText primary="Historique des commandes" />
        </ListItem>
        <ListItem onClick={()=>{setPage(4)}} className={clsx({ 'active-link': page === 4 })}>
          <ListItemText primary="Gestion des adresses" />
        </ListItem>
        <ListItem onClick={() => setOpenModal(!openModal)}className="bottom-link">
          <ListItemText primary="Déconnexion" />
        </ListItem>
      </List>
      
        </Drawer>
);
}
export default Sidebar