import Layout from "../Layout";
import "./catalogue.scss";
import Retour from "../../assets/icons/keyboard_arrow_left.svg";
import ImageSlider from "../../components/imageSlider";
import DetailsP from "../../components/details";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Services from "../../services/global.service";
const DetailsProduct = () => {
  const params = useParams();
  const [details, setDetails] = useState();
  const getDetails = () => {
    Services.getOne(params.id, "product").then((res) => {
      console.log("res details product", res?.data);
      setDetails(res?.data);
    });
  };

  useEffect(() => {
    getDetails();
  }, []);
  return (
    <Layout>
      <div className="container min-vh-100 mt-32">
        <img src={Retour} alt="retour icon" className="mr-8" />
        <a className="cta-retour" href="/catalogue">
          Retour
        </a>
        <div className="row mt-32">
          <div className="col-md-6">
            {details?.products_photos ? (
              <ImageSlider images={details?.products_photos} />
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6">
            <DetailsP data={details} />
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default DetailsProduct;
