// ProductForm.js
import React, { useState, useRef , useEffect} from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Services from "../../services/global.service";
import Alerts from "./Alerte";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";


const country = [
  { value: "Allemagne", label: "Allemagne" },
  { value: "Autriche", label: "Autriche" },
  { value: "Belgique", label: "Belgique" },
  { value: "Bulgarie", label: "Bulgarie" },
  { value: "Chypre", label: "Chypre" },
  { value: "Croatie", label: "Croatie" },
  { value: "Danemark", label: "Danemark" },
  { value: "Espagne", label: "Espagne" },
  { value: "Estonie", label: "Estonie" },
  { value: "Finlande", label: "Finlande" },
  { value: "France", label: "France" },
  { value: "Grèce", label: "Grèce" },
  { value: "Hongrie", label: "Hongrie" },
  { value: "Irlande", label: "Irlande" },
  { value: "Italie", label: "Italie" },
  { value: "Lettonie", label: "Lettonie" },
  { value: "Lituanie", label: "Lituanie" },
  { value: "Luxembourg", label: "Luxembourg" },
  { value: "Malte", label: "Malte" },
  { value: "Norvège", label: "Norvège" },
  { value: "Pays-Bas", label: "Pays-Bas" },
  { value: "Pologne", label: "Pologne" },
  { value: "Portugal", label: "Portugal" },
  { value: "République tchèque", label: "République tchèque" },
  { value: "Roumanie", label: "Roumanie" },
  { value: "Royaume-Uni", label: "Royaume-Uni" },
  { value: "Slovaquie", label: "Slovaquie" },
  { value: "Slovénie", label: "Slovénie" },
  { value: "Suède", label: "Suède" },
];

const FormAddAdresse = () => {
  const [message, setMessage] = useState("");
  const [visible, setVisible] = useState(false);
  const [color, setColor] = useState();
 // const [selectedCountry, setSelectedCountry] = useState("France");
 // const [selectedLcountry, setselectedLcountry] = useState("France");   
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const phoneRegExp = /^[0-9]{10}$/; 
  const validationSchema = Yup.object().shape({
    // Define validation rules for each field
    entite: Yup.string().required("le champ entité est obligatoire"),
    code: Yup.string().required("le champ code est obligatoire"),
    responsable: Yup.string().required("Le responsable est obligatoire"),
    telephone: Yup.string()
    .required("le numéro de téléphone est obligatoire")
    .matches(/^\+[0-9]/, 'Le numéro de téléphone doit commencer par +'),
    
    adresse: Yup.string().required("l'adresse est obligatoire"),
    // complement: Yup.string().required(
    //   "le complément d'adresse est obligatoire"
    // ),
    zipcode: Yup.string()
      .required("Veuillez saisir les données de ce champ.")
      .min(5, "Le numéro doit être composé de 5 chiffres")
      .matches(/^([0-9]{5})*$/, "Code postal invalide"),
    ville: Yup.string().required("la ville est obligatoire"),
    pays: Yup.string().required("le pays est obligatoire"),
    livraison: Yup.boolean(),
    lentite: Yup.string()
      .required("le champ entité est obligatoire")
      .when(["livraison"], {
        is: (livraison) => livraison === true,
        then: () => Yup.string().required("l'entité est obligatoire"),
        otherwise: () => Yup.string(),
      }),

    lentite: Yup.string()
      .required("le champ entité est obligatoire")
      .when(["livraison"], {
        is: (livraison) => livraison === true,
        then: () => Yup.string().required("le champ entité est obligatoire"),
        otherwise: () => Yup.string(),
      }),
    lresponsable: Yup.string()
      .required("Le responsable est obligatoire")
      .when(["livraison"], {
        is: (livraison) => livraison === true,
        then: () => Yup.string().required("Le responsable est obligatoire"),
        otherwise: () => Yup.string(),
      }),
    ltelephone: Yup.string()
    .matches(/^\+[0-9]/, 'Le numéro de téléphone doit commencer par +')
      .required("le numéro de téléphone est obligatoire")
      .when(["livraison"], {
        is: (livraison) => livraison === true,
        then: () => Yup.string().matches(/^\+[0-9]/, 'Le numéro de téléphone doit commencer par +')
        .required("le numéro de téléphone est obligatoire"),
        otherwise: () => Yup.string(),
      }),
    ladresse: Yup.string()
      .required("l'adresse est obligatoire")
      .when(["livraison"], {
        is: (livraison) => livraison === true,
        then: () => Yup.string().required("l'adresse est obligatoire"),
        otherwise: () => Yup.string(),
      }),
    //lcomplement: Yup.string().when(["livraison"], {
    //   is: (livraison) => livraison === true,
    //   then: () => Yup.string().required("* Required"),
    //   otherwise: () => Yup.string(),
    // }),
    lzipcode: Yup.string()
      .required("Veuillez saisir les données de ce champ.")
      .min(5, "Le numéro doit être composé de 5 chiffres")
      .matches(/^([0-9]{5})*$/, "Code postal invalide")
      .when(["livraison"], {
        is: (livraison) => livraison === true,
        then: () => Yup.string().required("Veuillez saisir les données de ce champ.")
        .min(5, "Le numéro doit être composé de 5 chiffres"),
        otherwise: () => Yup.string(),
      }),
    lville: Yup.string()
      .required("Le champ ville est obligatoire")
      .when(["livraison"], {
        is: (livraison) => livraison === true,
        then: () => Yup.string().required("la ville est obligatoire"),
        otherwise: () => Yup.string(),
      }),
  });

  const fileRef = useRef();
  useEffect(() => {
    const user = Services.getAll("users/list").then(
      (response) => {
        if (response.data.users) {
          setUsers(response.data.users);
        }
        // navigate("/catalogue");
      },
      (error) => {}
    );
  }, []);
  return (
    <>
      <Alerts
        color={color}
        msg={message}
        visible={visible}
        setVisible={setVisible}
      />
      <Formik
        initialValues={{
          user: "",
          entite: "",
          code: "",
          responsable: "",
          telephone: "",
          adresse: "",
          complement: "",
          zipcode: "",
          ville: "",
          pays: "France",
          lentite: "",
          lresponsable: "",
          ltelephone: "",
          ladresse: "",
          lcomplement: "",
          lzipcode: "",
          lville: "",
          lpays: "France",
          livraison: false,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          // same shape as initial values
          console.log(values);
        //  values.pays = selectedCountry;
        //  values.lpays = selectedLcountry;
          
          console.log("there is all the value",values)
          Services.create(values, "adresses/store").then(
            (response) => {
              console.log("res", response.data.status);
              if (response.data.status === 1) {
                navigate("/admin/adresses");
              } else {
                setMessage("Erreur d'ajout");
                setVisible(true);
                setColor("danger");
              }
              // navigate("/catalogue");
            },
            (error) => {
              console.log("inputs not valide", error.response.data.message);
              setMessage(error.response.data.message);
              return;
            }
          );
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
          touched,
          values,
        }) => (
          <Form className="form-container">
            <div className="row">
              <div className="col-md-6">
                <div className="border-adresse">
                  <h1 className="mt-20">Adresse livraison</h1>
                  <div className="row mt-12">
                    <div className="col">
                      <TextField
                        id="user"
                        name="user"
                        select
                        label="Client"
                        defaultValue={values.user}
                        SelectProps={{
                          native: true,
                        }}
                        variant="standard"
                        error={!!touched.user && !!errors.user}
                        onChange={handleChange}
                        helperText={touched.user && errors.user}
                      >
                        <option>Selectionner le client</option>
                        {users.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.nom} {option.prenom}
                          </option>
                        ))}
                      </TextField>
                    </div>
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col">
                      <TextField
                        type="text"
                        id="entite"
                        name="entite"
                        label="Entité"
                        variant="standard"
                        error={!!touched.entite && !!errors.entite}
                        helperText={touched.entite && errors.entite}
                        defaultValue={values.entite}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col">
                      <TextField
                        type="text"
                        id="code"
                        name="code"
                        label="Code"
                        variant="standard"
                        error={!!touched.code && !!errors.code}
                        helperText={touched.code && errors.code}
                        defaultValue={values.entite}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mt-12">
                    <div className="col">
                      <TextField
                        type="text"
                        id="responsable"
                        name="responsable"
                        className="form-control mt-10"
                        label="Responsable"
                        variant="standard"
                        error={!!touched.responsable && !!errors.responsable}
                        helperText={touched.responsable && errors.responsable}
                        defaultValue={values.responsable}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col">
                      <TextField
                        type="text"
                        id="telephone"
                        name="telephone"
                        className="form-control mt-10"
                        label="Téléphone"
                        variant="standard"
                        error={!!touched.telephone && !!errors.telephone}
                        helperText={touched.telephone && errors.telephone}
                        defaultValue= {values.telephone}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mt-12">
                    <div className="col">
                      <TextField
                        type="text"
                        id="adresse"
                        name="adresse"
                        className="form-control mt-10"
                        label="Adresse"
                        variant="standard"
                        error={!!touched.adresse && !!errors.adresse}
                        helperText={touched.adresse && errors.adresse}
                        defaultValue={values.adresse}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="row mt-12">
                    <div className="col">
                      <TextField
                        type="text"
                        id="complement"
                        name="complement"
                        className="form-control mt-10"
                        label="Complément d’adresse"
                        variant="standard"
                        error={!!touched.complement && !!errors.complement}
                        helperText={touched.complement && errors.complement}
                        defaultValue={values.complement}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mt-12">
                    <div className="col">
                      <TextField
                        type="text"
                        id="zipcode"
                        name="zipcode"
                        className="form-control mt-10"
                        label="Code postal"
                        variant="standard"
                        error={!!touched.zipcode && !!errors.zipcode}
                        helperText={touched.zipcode && errors.zipcode}
                        defaultValue={values.zipcode}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col">
                      <TextField
                        type="text"
                        id="ville"
                        name="ville"
                        className="form-control mt-10"
                        label="Ville"
                        variant="standard"
                        error={!!touched.ville && !!errors.ville}
                        helperText={touched.ville && errors.ville}
                        defaultValue={values.ville}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mt-12">
                    <div className="col">
                    <TextField
                        id="pays"
                        name="pays"
                        select
                        label="Pays"
                        value={values.pays}
                        defaultValue="France"  
                        SelectProps={{
                          native: true,
                        }}
                        variant="standard"
                        error={!!touched.pays && !!errors.pays}
                        onChange={handleChange}
                        helperText={touched.pays && errors.pays}
                      >
                        {country.map((option) => (
                          <option key={option.value} value={option.value} >
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </div>
                  </div>

                  <div className="row mt-12">
                    <div className="col">
                      <label>
                        <Field
                          type="checkbox"
                          name="livraison"
                          id="livraison"
                          checked={values.livraison}
                          className="m-4"
                          value={values.livraison}
                          onChange={handleChange}
                          error={!!touched.livraison && !!errors.livraison}
                          helperText={touched.livraison && errors.livraison}
                        />
                        L’adresse de facturation est différente de l’adresse de
                        livraison
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6"
                style={
                  values.livraison ? { display: "block" } : { display: "none" }
                }
              >
                <div className="border-adresse">
                  <h1 className="mt-20">Adresse de facturation</h1>
                  <div className="row">
                    <div className="col">
                      <TextField
                        type="text"
                        id="lnom"
                        name="lentite"
                        className="form-control mt-10"
                        label="Entité"
                        variant="standard"
                        error={!!touched.lentite && !!errors.lentite}
                        helperText={touched.lentite && errors.lentite}
                        defaultValue={values.lentite}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col"></div>
                  </div>
                  <div className="row mt-12">
                    <div className="col">
                      <TextField
                        type="text"
                        id="lresponsable"
                        name="lresponsable"
                        className="form-control mt-10"
                        label="Responsable"
                        variant="standard"
                        error={!!touched.lresponsable && !!errors.lresponsable}
                        helperText={touched.lresponsable && errors.lresponsable}
                        defaultValue={values.lresponsable}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col">
                      <TextField
                        type="text"
                        id="ltelephone"
                        name="ltelephone"
                        className="form-control mt-10"
                        label="Téléphone"
                        variant="standard"
                        error={!!touched.ltelephone && !!errors.ltelephone}
                        helperText={touched.ltelephone && errors.ltelephone}
                        defaultValue={values.ltelephone}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mt-12">
                    <div className="col">
                      <TextField
                        type="text"
                        id="ladresse"
                        name="ladresse"
                        className="form-control mt-10"
                        label="Adresse"
                        variant="standard"
                        error={!!touched.ladresse && !!errors.ladresse}
                        helperText={touched.ladresse && errors.ladresse}
                        defaultValue={values.ladresse}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="row mt-12">
                    <div className="col">
                      <TextField
                        type="text"
                        id="lcomplement"
                        name="lcomplement"
                        className="form-control mt-10"
                        label="Complément d’adresse"
                        variant="standard"
                        error={!!touched.lcomplement && !!errors.lcomplement}
                        helperText={touched.lcomplement && errors.lcomplement}
                        defaultValue={values.lcomplement}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mt-12">
                    <div className="col">
                      <TextField
                        type="text"
                        id="lzipcode"
                        name="lzipcode"
                        className="form-control mt-10"
                        label="Code postal"
                        variant="standard"
                        error={!!touched.lzipcode && !!errors.lzipcode}
                        helperText={touched.lzipcode && errors.lzipcode}
                        defaultValue={values.lzipcode}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col">
                      <TextField
                        type="text"
                        id="lville"
                        name="lville"
                        className="form-control mt-10"
                        label="Ville"
                        variant="standard"
                        error={!!touched.lville && !!errors.lville}
                        helperText={touched.lville && errors.lville}
                        defaultValue={values.lville}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mt-12">
                    <div className="col">
                    <TextField
                        id="lpays"
                        name="lpays"
                        select
                        label="Pays"
                        value={values.lpays}
                        defaultValue="France"                    
                        SelectProps={{
                          native: true,
                        }}
                        variant="standard"
                        error={!!touched.lpays && !!errors.lpays}
                        onChange={handleChange}
                        helperText={touched.lpays && errors.lpays}
                      >
                        
                        {country.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-end">
              <button
                type="submit"
                className="btn-primary mt-20 "
                onClick={() => handleSubmit}
              >
                Sauvegarder
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormAddAdresse;
