import Layout from "../../LayoutAdmin";
import Services from "../../../services/global.service";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BreadcrumbPage from "../../../components/breadcrump";
import Datatables from "./../../../components/datatables";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import moment from 'moment'
import { CSVLink } from "react-csv";
import DeletePopup from "../../../components/admin/deletePopup";

const IMG_URL = process.env.REACT_APP_FILE_BASE_URL || "";

const addCTA = (param) => {
  return (
    <div className="action">
      <Link to={`/admin/commandes/${param?.id}`}>
        <RemoveRedEyeIcon />
      </Link>
      {/* <Link to={`/admin/products/edit/${param?.id}`}>
        <BorderColorIcon />
      </Link>
      <DeletePopup
        id={`${param?.id}`}
        name={`${param?.nom}`}
        endpoint="product/delete"
      /> */}

      <DeletePopup
        id={`${param?.id}`}
        name={`${param?.nom}`}
        endpoint="commandes/delete"
      />
    </div>
  );
};


const columns = [
  {
    name: "ID",
    selector: (row) => row.id,
    sortable: true,
    width: 10,
  },
  {
    name: "REFERENCE",
    selector: (row) => row.reference,
    sortable: true,
    width: 10,
  },
  {
    name: "Client",
    selector: (row) => row.commandes_client?.bunit,
    sortable: true,
    width: 10,
  },
  {
    name: "prix",
    selector: (row) => row.total + ' HT €',
    sortable: true,
    width: 10,
  },
  {
    name: "Statut",
    selector: (row) => row.statut,
    sortable: true,
    width: 10,
  },
  {
    name: "Date", 
    selector: (row) => moment(row.created_at).format("YYYY/MM/DD kk:mm:ss"),
    sortable: true,
    width: 10,
  },

  {
    name: "Action",
    cell: (param) => addCTA(param),
  },
];

let rows = [];

const ListCommandes = () => {
  const [data, setdata] = useState();

  useEffect(() => {
    Services.getAll("commandes/list").then(
      (response) => {
        console.log(response.data);
        if (response.data) {
          setdata(response.data);
          response.data.forEach((element) => {
            rows = response.data;
          });
        }
        // navigate("/catalogue");
      },
      (error) => {}
    );
  }, []);
  const csvData = [
    { label: "Reference", key: "reference" },
    { label: "Statut", key: "statut" },
    { label: "Date commande", key: "date" },
    { label: "Total commande", key: "total" },
    { label: "Note", key: "note" },
    { label: "Article", key: "article" },
    { label: "PrixArticle", key: "articlePrice" },
    { label: "Email Client", key: "client" },
    { label: "Client tel", key: "clientTel" },
    { label: "Client", key: "clientName" },
    { label: "addresse", key: "addresse" },
    { label: "code", key: "code" },
    { label: "qte_adresse", key: "qte" },
    { label: "responsable", key: "responsable" },
    { label: "complement", key: "complement" },
    { label: "entite", key: "entite" },
    { label: "pays", key: "pays" },
    { label: "phone", key: "phone" },
    { label: "type", key: "type" },
    { label: "ville", key: "ville" },
    { label: "zipcode", key: "zipcode" }
  ];
  const getCSVData = (data) => {
    // Check if data is available
    if (!data) {
      return [];
    }
  
    // Map through data and format for CSV export
    const formattedData = data.flatMap((command) => {
      return command.commandes.flatMap((row) => {
        // Map through addresses and format for CSV export
        const addresses = row.commandes_articles_adresses?.map((adr) => ({
          reference: command.reference,
          date: command.created_at,
          total: command.total + '€',
          statut: command.statut,
          note: command.note,
          client: command.commandes_client?.email,
          clientTel: command.commandes_client?.phone,
          clientName: `${command.commandes_client?.nom} ${command.commandes_client?.prenom}`,
          article: row.article?.nom,
          articlePrice: row?.article?.prix + '€',
          addresse: `${adr?.commandes_adresse?.adresse}`, // Keep it as an array
          code: `${adr?.commandes_adresse?.code}`,
          qte: `${adr?.qte}`,
          responsable : `${adr?.commandes_adresse?.responsable}`,
          complement  : `${adr?.commandes_adresse?.complement}` ,
          entite      : `${adr?.commandes_adresse?.entite}` ,
          pays        : `${adr?.commandes_adresse?.pays}`,
          phone       : `${adr?.commandes_adresse?.phone}`,
          type        : `${adr?.commandes_adresse?.type}`,
          ville       : `${adr?.commandes_adresse?.ville}`,
          zipcode     : `${adr?.commandes_adresse?.zipcode}`
        }));
  
        return addresses;
      });
    });
    console.log('formattedData',formattedData);
    return formattedData;
  };

  return (
    <Layout>
      <div className="container">
        <BreadcrumbPage parent="Commandes" page="Liste des commandes" sub="" />
        <div className="row">
          <div className="col-md-6">
            <h1>Liste des commandes</h1>
          </div>
          <div className="col-md-6 right-aj">
          {data && 
                    <CSVLink
                    data={getCSVData(data)} // Pass the formatted data directly
                    headers={csvData}
                    filename={"commande_data.csv"}
                    className="btn-primary ms-2"
                    separator=","
                  >
                    Export to CSV
                  </CSVLink>
               }
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Datatables columns={columns} data={rows} />
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ListCommandes;
